import restaurant from '../assets/restaurant.jpg';
import online_store from '../assets/online_store.jpg';
import coding from '../assets/coding.jpg';
import shoe_store from '../assets/shoe_store.png';
import gpt3 from '../assets/gpt3.png';
import grooming from '../assets/grooming.png'

export const images ={
    restaurant,
    online_store,
    coding,
    shoe_store,
    gpt3,
    grooming
}
