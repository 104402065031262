import React from 'react';
import './experience.css';
import Article from '../../containers/Article';
import {Markup} from './exp';
import {BackEnd} from './exp';

const Experience = () => {
  return (
    <div className='experience_flex-container section_padding' id='experience'>
          
            <div data-aos="fade-up"className="experience_title">
                  <p data-aos="fade-up">Explore My</p>
                  <h1>Experience</h1>
            </div>
            <div data-aos="flip-left" className="experience_content">
                    <div className="experience_card">
                      <div className="card_title">
                        <h1>Frontend Development</h1>
                      </div>
                      <div className="card_info">
                        {
                          Markup.map((item)=>{
                            return <Article text={item.language} level={item.level}/>
                          })
                        }
                        </div>
                    </div>
                    <div className="experience_card">
                        <div className="card_title">
                          <h1>Frontend Development</h1>
                        </div>
                        <div className="card_info">
                        {
                          BackEnd.map((item)=>{
                            return <Article text={item.language} level={item.level}/>
                          })
                        }
                        </div>
                        
                    </div>
            </div>
    </div>
  )
}

export default Experience