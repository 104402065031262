export const Markup = [
    {
        id:1,
        language: 'HTML',
        level: 'advanced'
    },
    {
        id:2,
        language: 'CSS',
        level: 'profficient'
    },
    {
        id:3,
        language: 'SASS',
        level: 'basic'
    },
    {
        id:4,
        language: 'JavaScript',
        level: 'Profficient'
    },
    {
        id:5,
        language: 'TypeScript',
        level: 'intermediate'
    },
    {
        id:6,
        language: 'Material UI',
        level: 'basic'
    },
]

export const BackEnd = [
    {
        id:7,
        language: 'Node JS',
        level: 'Intermediate'
    },
    {
        id:8,
        language: 'Express JS',
        level: 'Intermediate'
    },
    {
        id:9,
        language: 'MySQL',
        level: 'Advanced'
    },
    {
        id:10,
        language: 'MongoDB',
        level: 'Intermediate'
    },
    {
        id:11,
        language: 'Git',
        level: 'Intermediate'
    },
]