
import './about.css';
import aboutImg from '../../assets/portfolio_img2.jpg';
import { FaBriefcase, FaGraduationCap } from 'react-icons/fa';
const About = () => {
 
  return (
    <div className='about_flex-container section_padding' id='about'>
      <div className="about_title">
        <p data-aos="fade-up">Get to Know Me</p>
        <h1 data-aos="fade-down">About Me</h1>
      </div>
      <div className="about_content">
        <div data-aos="fade-up" className="about_image">
            <img src={aboutImg}/>
        </div>
        <div data-aos="fade-down" className="about_info">
          <div className="about_cards">
              <div className="about_experience">
                 <FaBriefcase size={27}/>
                 <h3>Experience</h3>
                 <p>1+ years front end development</p>
              </div>
              <div className="about_education">
                 <FaGraduationCap size={30} />
                 <h3>Education</h3>
                 <p>B.Sc. Bachelor's Degree</p>
              </div>
          </div>
          <div className="about_story">
            <p>Hello, I'm Josue Cruz, an enthusiastic front-end developer with 1+ years of experience in creating scalable web applications.  I am passionate about creating and engaging user experience by bringing designs to life through my coding skills. I have proficiency skills in HTML and CSS with hands on experience in using JavaScript, jQuery, React and responsive design. I am interested about learning new ways to constantly improve system design and tackle complex challenges and find solutions to enhance web functionalities. I am always open to new opportunities and collaborations, even if it is to talk about any inquiries related to web design. Alongside with coding, I am a very active person who loves sports and likes to follow a healthy lifestyle. Please do not hesitate to contact me and let's start working together to create more amazing web experiences!</p>
          </div>
          
        </div>
         
      </div>
    </div>
  )
}

export default About