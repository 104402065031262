import React from 'react';
import './article.css';
import { PiSealCheckBold } from 'react-icons/pi';

const Article = ({text,level}) => {
  return (
    <div className='article_flex-container'>
        <div className="article_card-image">
                <PiSealCheckBold color='#3CB371	' size={30}/>
        </div>
        <div className="article_card_text">
              <h2>{text}</h2>
              <p>{level}</p>
        </div>
    </div>
  )
}

export default Article