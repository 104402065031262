import React from 'react';
import './projects.css';
import Project from '../../containers/project/Project';
import {images} from '../../containers/imageExports';
const Projects = () => {
  return (
    <div className='project_flex-container section_padding' id='projects'>
      <div className="project_title">
            <p data-aos="fade-up">Browse My Recent</p>
            <h1 data-aos="fade-down">Projects</h1>
      </div>
      <div className="project_content">
          <Project image={images.gpt3} number= 'GPT3 Clone' url = 'http://prjct-two.s3-website.us-east-2.amazonaws.com/'/>
          <Project image={images.shoe_store} number = 'Shoe Store' url='http://prjct-one.s3-website.us-east-2.amazonaws.com/'/>
          <Project image={images.grooming} number ='Grooming' url='http://grooming-app.s3-website.us-east-2.amazonaws.com/'/>
          
      </div>
    </div>
  )
}

export default Projects