import React from 'react';
import './project.css'

const Project = ({image,number,url}) => {
  return (
    <div className='project_container'>
        <div className="project_image">
            <img src={image} alt="project img"/>
        </div>
        <div className="project_container_content">
            <h2>{number}</h2>
        </div>
        <div className="project_actions">
            {
              //<a><button type='button'>Github</button></a>
            }
            <a href={url} target='_blank'><button type='button'>Live Demo</button></a>
        </div>
    </div>
  )
}

export default Project