import React from 'react';
import './header.css';
//import portfolioImg from '../../assets/portfolio_main_image.png';
import portfolioImg from '../../assets/test2.png';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { SiIndeed } from 'react-icons/si';

/*const PDF_FILE_URL = 'http://localhost:3000/resume.pdf';*/

const Header = () => {
  /*const downloadFile = (url) =>{
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download",fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }*/
  const downloadResume = () => {
    const resumeUrl = process.env.PUBLIC_URL + '/files/resume.pdf';
    const a = document.createElement('a');
    a.href = resumeUrl;
    a.download = 'resume.pdf';
    a.click();
  }
  return (
    <div className='header_flex-container section_padding'>
      <div className="header_content">
        <div className="header_title">
            <h1>Hello, I am Josue. </h1>
            <h1>Front End Developer</h1>
            <p>I am a front end developer who loves creating solid and scalable user interfaces with great user experience </p>
        </div>
        <div className="btn_contact">
          <button type='button' onClick={downloadResume}>Download Resume</button>
        </div>
        <div className="professional_links">
            <a href='https://www.linkedin.com/in/josue-cruz-83a081218/' target='_blank'><FaLinkedinIn /></a>
            <a href='https://github.com/josue0128' target='_blank'><FaGithub /></a>
            <a href='https://profile.indeed.com/?hl=en_US&co=US&from=gnav-homepage&_ga=2.219278234.2047646741.1696796353-2011647283.1683859927' target='_blank'><SiIndeed /></a>
        </div>
      </div>
      <div className="header_image">
          <img src={portfolioImg} />
      </div>
    </div>
  )
}

export default Header