import React, { useState } from 'react';
import './navbar.css';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'

function Menu() {

  const [activeItem, setActiveItem] = useState('about');
  const handleClick = (item) =>{
    setActiveItem(item);
  }
  return(
    <>
    <p><a href='#about' className={activeItem === 'about' ? 'active' :''} onClick={() => handleClick('about')}>About</a></p>
    <p><a href='#experience' className={activeItem === 'experience' ? 'active' :''} onClick={() => handleClick('experience')}>Experience</a></p>
    <p><a href='#projects' className={activeItem === 'projects' ? 'active' :''} onClick={() => handleClick('projects')}>Projects</a></p>
    <p><a href='#contact' className={activeItem === 'contact' ? 'active' :''} onClick={() => handleClick('contact')}>Contact Me</a></p>
    </>
  )
}

const Navbar = () => {
  
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='navbar_flex-container'>
      <div className="logo">
          <h1>Portfolio</h1>
      </div>
      <div className="links_container">
          <Menu />
      </div>
      <div className="menu_mobile">
            {
              toggleMenu
              ?<RiCloseLine color='#fff' size={27} onClick={() => {setToggleMenu(false)}}/>
              :<RiMenu3Line color='#fff' size={27} onClick={() => {setToggleMenu(true)}}/>
            }
            {
              toggleMenu && (
                <div className="menu_mobile-container">
                   <Menu />
                </div>
              )
            }
      </div>
    </div>
  )
}

export default Navbar