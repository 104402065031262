import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className='footer_flex-container section_padding'>
      <div className="nav_links">
            <a href='#about'>About</a>
            <a href='#experience'>Experience</a>
            <a href='#projects'>Projects</a>
            {
            //<a href='#contact'>Contact</a> 
            }
      </div>
      <div className="footer_copyright">
            <p>Copyright &#169; 2023 Josue Cruz. All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer