
import React, { useEffect } from 'react';
import './App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Navbar, Header, About, Experience, Projects, Contact,Footer} from './components';
function App() {
  useEffect(()=>{
    Aos.init({duration: 2000});
  },[])
  return (
    <div className='App'>
      <div className="gradient_bg">
          <Navbar />
          <Header />
      </div>
      <div>
        <About />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
    </div>
  </div>
  );
}

export default App;
