import React from 'react';
import './contact.css';
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className='contact_flex-container section_padding section__margin' id='contact'>
        <div className="contact_title">
            <p>Get In Touch</p>
            <h1>Contact Me</h1>
        </div>
        <div className="contact_info">
            <div className="contact_info_icon">
                <FaEnvelope color='#fff' size={27} /><a href='mailto:josuec@cpp.edu' target='_blank'>josuec@cpp.edu</a> 
            </div>
            <div className="contact_info_icon">
                <FaLinkedin color='#fff' size={27} /><a href='https://www.linkedin.com/in/josue-cruz-83a081218/' target='_blank'>LinkedIn</a> 
            </div>
        </div>
    </div>
  )
}

export default Contact